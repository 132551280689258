import TributeFormActions from "../actions/TributeFormActions"

const initialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: null,
  successMessage: null,
  imagesIds: [],
  cloudinaryImages: [],
  allMoms: [],
  isFetchingMoms: false,
  momsFetchError: null,
  momsFetchSuccess: null,
  existedMoms: [],
  reTribute: false,
}

const tributeFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case TributeFormActions.IMAGE_UPLOAD_TO_CLOUDINARY:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: null,
        successMessage: null,
      }
    case TributeFormActions.IMAGE_UPLOAD_TO_CLOUDINARY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: null,
        successMessage: action.payload.successMessage,
      }
    case TributeFormActions.IMAGE_UPLOAD_TO_CLOUDINARY_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: action.payload.errorMessage,
        successMessage: null,
      }
    case TributeFormActions.IMAGE_UPLOAD_TO_CONTENTFUL:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: null,
        successMessage: null,
      }
    case TributeFormActions.IMAGE_UPLOAD_TO_CONTENTFUL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: null,
        successMessage: action.payload.successMessage,
      }
    case TributeFormActions.IMAGE_UPLOAD_TO_CONTENTFUL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: action.payload.errorMessage,
        successMessage: null,
      }
    case TributeFormActions.TRIBUTE_FORM_SUBMISSION:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: null,
        successMessage: null,
      }
    case TributeFormActions.TRIBUTE_FORM_SUBMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: null,
        successMessage: action.payload.successMessage,
      }
    case TributeFormActions.TRIBUTE_FORM_SUBMISSION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isSuccess: false,
        errorMessage: action.payload.errorMessage,
        successMessage: null,
      }
    case TributeFormActions.GET_ALL_MOMS:
      return {
        ...state,
        isFetchingMoms: true,
      }
    case TributeFormActions.GET_ALL_MOMS_SUCCESS:
      return {
        ...state,
        isFetchingMoms: false,
        allMoms: action.payload.allMoms,
        momsFetchSuccess: action.payload.successMessage,
        momsFetchError: null,
      }
    case TributeFormActions.GET_ALL_MOMS_FAILED:
      return {
        ...state,
        isFetchingMoms: false,
        momsFetchSuccess: null,
        momsFetchError: action.payload.errorMessage,
      }
    case TributeFormActions.SET_EXISTED_MOM:
      return {
        ...state,
        existedMoms: action.payload.existedMoms,
      }
    case TributeFormActions.MOM_NOT_EXISTED:
      return {
        ...state,
        existedMoms: [],
      }
    case TributeFormActions.TOGGLE_TRIBUTE:
      return {
        ...state,
        reTribute: !state.reTribute,
      }

    default:
      return state
  }
}

export default tributeFormReducer
