export default class TributeFormActions {
  static TRIBUTE_FORM_SUBMISSION = "TRIBUTE_FORM_SUBMISSION"
  static TRIBUTE_FORM_SUBMISSION_SUCCESS = "TRIBUTE_FORM_SUBMISSION_SUCCESS"
  static TRIBUTE_FORM_SUBMISSION_FAILED = "TRIBUTE_FORM_SUBMISSION_FAILED"
  static IMAGE_UPLOAD_TO_CLOUDINARY = "IMAGE_UPLOAD_TO_CLOUDINARY"
  static IMAGE_UPLOAD_TO_CLOUDINARY_SUCCESS =
    "IMAGE_UPLOAD_TO_CLOUDINARY_SUCCESS"
  static IMAGE_UPLOAD_TO_CLOUDINARY_FAILED = "IMAGE_UPLOAD_TO_CLOUDINARY_FAILED"
  static IMAGE_UPLOAD_TO_CONTENTFUL = "IMAGE_UPLOAD_TO_CONTENTFUL"
  static IMAGE_UPLOAD_TO_CONTENTFUL_SUCCESS =
    "IMAGE_UPLOAD_TO_CONTENTFUL_SUCCESS"
  static IMAGE_UPLOAD_TO_CONTENTFUL_FAILED = "IMAGE_UPLOAD_TO_CONTENTFUL_FAILED"
  static GET_ALL_MOMS = "GET_ALL_MOMS"
  static GET_ALL_MOMS_SUCCESS = "GET_ALL_MOMS_SUCCESS"
  static GET_ALL_MOMS_FAILED = "GET_ALL_MOMS_FAILED"
  static SET_EXISTED_MOM = "SET_EXISTED_MOM"
  static MOM_NOT_EXISTED = "MOM_NOT_EXISTED"
  static TOGGLE_TRIBUTE = "TOGGLE_TRIBUTE"

  static setExistedMom(existedMoms, reTribute) {
    return {
      type: this.SET_EXISTED_MOM,
      payload: { existedMoms: existedMoms, reTribute: reTribute },
    }
  }
  static momNotExisted() {
    return {
      type: this.MOM_NOT_EXISTED,
    }
  }
  static toggleReTribute() {
    return {
      type: this.TOGGLE_TRIBUTE,
    }
  }

  static tributeFormSubmission() {
    return {
      type: this.TRIBUTE_FORM_SUBMISSION,
    }
  }
  static tributeFormSubmissionSuccess({ successMessage }) {
    return {
      type: this.TRIBUTE_FORM_SUBMISSION_SUCCESS,
      payload: { successMessage },
    }
  }
  static tributeFormSubmissionFailed({ errorMessage }) {
    return {
      type: this.TRIBUTE_FORM_SUBMISSION_FAILED,
      payload: { errorMessage },
    }
  }
  static imageUploadToCloudinary() {
    return {
      type: this.IMAGE_UPLOAD_TO_CLOUDINARY,
    }
  }
  static imageUploadToCloudinarySuccess({ successMessage }) {
    return {
      type: this.IMAGE_UPLOAD_TO_CLOUDINARY_SUCCESS,
      payload: { successMessage },
    }
  }
  static imageUploadToCloudinaryFailed({ errorMessage }) {
    return {
      type: this.IMAGE_UPLOAD_TO_CLOUDINARY_FAILED,
      payload: { errorMessage },
    }
  }
  static imageUploadToContentful() {
    return {
      type: this.IMAGE_UPLOAD_TO_CONTENTFUL,
    }
  }
  static imageUploadToContentfulSuccess({ successMessage }) {
    return {
      type: this.IMAGE_UPLOAD_TO_CONTENTFUL_SUCCESS,
      payload: { successMessage },
    }
  }
  static imageUploadToContentfulFailed({ errorMessage }) {
    return {
      type: this.IMAGE_UPLOAD_TO_CONTENTFUL_FAILED,
      payload: { errorMessage },
    }
  }

  static getAllMoms() {
    return {
      type: this.GET_ALL_MOMS,
    }
  }
  static getAllMomsSuccess({ allMoms, successMessage }) {
    return {
      type: this.GET_ALL_MOMS_SUCCESS,
      payload: { allMoms, successMessage },
    }
  }
  static getAllMomsFailed({ errorMessage }) {
    return {
      type: this.GET_ALL_MOMS_FAILED,
      payload: { errorMessage },
    }
  }
}
