import React from "react"
import { Provider } from "react-redux"
import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./src/store/index"
import { composeWithDevTools } from "redux-devtools-extension"

// const loadDevTools = () =>
//   process.env.NODE_ENV === "development" && window.devToolsExtension
//     ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
//       window.__REDUX_DEVTOOLS_EXTENSION__()
//     : (f) => f;

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
)

export default ({ element }) => <Provider store={store}>{element}</Provider>
