// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-articles-jsx": () => import("./../../../src/pages/articles.jsx" /* webpackChunkName: "component---src-pages-articles-jsx" */),
  "component---src-pages-create-tribute-jsx": () => import("./../../../src/pages/create-tribute.jsx" /* webpackChunkName: "component---src-pages-create-tribute-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-status-jsx": () => import("./../../../src/pages/status.jsx" /* webpackChunkName: "component---src-pages-status-jsx" */),
  "component---src-pages-suggest-article-jsx": () => import("./../../../src/pages/suggest-article.jsx" /* webpackChunkName: "component---src-pages-suggest-article-jsx" */),
  "component---src-pages-tributes-jsx": () => import("./../../../src/pages/tributes.jsx" /* webpackChunkName: "component---src-pages-tributes-jsx" */),
  "component---src-templates-content-page-template-jsx": () => import("./../../../src/templates/content-page-template.jsx" /* webpackChunkName: "component---src-templates-content-page-template-jsx" */),
  "component---src-templates-tribute-template-jsx": () => import("./../../../src/templates/tribute-template.jsx" /* webpackChunkName: "component---src-templates-tribute-template-jsx" */)
}

